<template>
  <div class="c-app flex-row align-items-center">
    <mini-loader v-if="load"></mini-loader>
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit="login">
                  <h1>Войти</h1>
                  <p class="text-muted">Войти в аккаунт</p>
                  <CInput
                    placeholder="Email"
                    autocomplete="username email"
                    v-model="user.email"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Пароль"
                    type="password"
                    autocomplete="curent-password"
                    v-model="user.password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" type="submit">Войти</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <!-- <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton> -->
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Eastline Express</h2>
                <p>Система управления данными</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Eastline Express
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',
  data(){
    return {
      load: false,
      user: {
        email: '',
        password: ''
      }
    }
  },
  methods:{
    login(){
      event.preventDefault();
      this.load = true;
      axios.post('/login', this.user)
      .then((response) => {
        if(response.status == 200){
          localStorage.setItem('token', response.data.success.access_token);
          axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
          axios.get('/user').then((response)  =>  {
            if(response.status == 200){
              localStorage.setItem('me', JSON.stringify(response.data));
              localStorage.setItem('auth', true);
              this.$forceUpdate();
              this.$router.push('/dashboard');
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          this.$message.error(
            `Неверный логин или пароль`,
            3
          );
        }
        else if(error.response.status == 403){
          this.$message.error(
            error.response.data.error,
            3
          );
        }else if(error.response.status == 422){
          this.$message.error(
            error.response.data.error,
            3
          );
        }
      })
      .finally(() => {
        this.load = false;
      })
    }
  }
}
</script>
